export default (locale) => {
  switch (locale) {
    case 'sr-Latn':
      return import(/* webpackChunkName: "i18n.common.sr-Latn" */'./common.sr-Latn');
    case 'hr':
      return import(/* webpackChunkName: "i18n.common.hr" */'./common.hr');
    case 'ro':
      return import(/* webpackChunkName: "i18n.common.hr" */'./common.ro');
    case 'fr':
      return import(/* webpackChunkName: "i18n.common.hr" */'./common.fr');
    default:
      return import(/* webpackChunkName: "i18n.common" */'./common');
  }
};
